.cl-header {
    background-color: #ffffff;
    padding: 16px;
    height: fit-content;
    overflow: hidden;

    &__logo {
        cursor: pointer;
        
        img {
            object-fit: contain;
            width: 100%;
            height: 60px;
        }
    }

    &__actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        a {
            text-decoration: none;
        }
    }

    nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        & > div {
            &:nth-child(2) {
                gap: 16px;
            }
        }

        ul {
            list-style-type: none;
            margin: 0 auto;
            padding: 0;
            // flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 32px;

            li {
                display: inline-block;
                margin-right: 20px;

                a {
                    text-decoration: none;
                    color: #000;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }
    }

    @media (max-width: 768px) {

        &__logo {
            width: fit-content;
            img {
                width: 100px;
            }
        }

        &__actions {
            display: none;
        }

        nav {
            ul {
                display: none;
            }
        }
    }
}