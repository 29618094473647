.get-a-quote-modal {
    background-color: #fff;
    border: 1px solid #EC1B25;
    position: relative;
    z-index: 13;

    .close {
        z-index: 99;
        position: absolute;
        top: -10px;
        right: -10px;
        color: #000;
        background-color: #EC1B25;
        line-height: 24px;
        height: 24px;
        width: 24px;
        cursor: pointer;
        border-radius: 50%;
        transition: all 0.3s ease;

        &:hover {
            opacity: .8;
        }
    }

    form {
        position: relative;

        h1 {
            font-size: 36px;
            font-weight: 800;
            opacity: 0.7;
        }

        button {
            background-color: #EC1B25;
            color: #fff;
            outline: none;
            border: none;
            padding: 8px;
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 6px;
            cursor: pointer;
            transition: all 0.5s ease;

            &:hover {
                opacity: .7;
            }
        }
    }

    .hero {
        background-color: #7F7F7F;
        color: #fff;

        .logo {
            height: 200px;
            border-radius: 50%;
            width: 200px;
            overflow: hidden;
            padding: 10px;
            background-color: rgba($color: #fff, $alpha: 0.5);

            img {
                object-fit: contain;
                background-color: #fff;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                object-fit: contain;
            }
        }

        h1 {
            font-size: 30px;
            font-weight: 800;
            text-transform: uppercase;
        }
    }

    .success {
        p {
            font-size: larger;
            width: 70%;
            margin: 0 auto;
            text-align: center;
            opacity: .9;
        }

        h3, p {
            animation: fadeIn 0.5s ease-in;
        }
    }
}