.cl-contact {
    gap: 32px;

    & > div {
        flex: 1;
    }

    & > div.info {
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: start;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;

        h4 {
            font-weight: 600;
            font-size: 55px;
            line-height: 76px;
        }

        & > div {
            display: flex;
            flex-direction: row;
            text-align: start;

            svg {
                margin-top: 4px;
                color: #EC1B25;
            }

            span {
                display: block;
                &:last-child {
                    opacity: .8;
                }
            }
        }
    }

    @media (max-width: 768px) {
        & > div:first-child {
            display: none;
        }

        & > div.info {
            font-size: 32px;
            line-height: 38px;
            text-align: center;

            h4  {
                font-size: 36px;
                line-height: 50px;
            }
        }
    }
}