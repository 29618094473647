.cl-hero-section {
    background-image: url(../../Assets/Images/hero-bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: fit-content;
    min-height: 700px;
    position: relative;

    a {
        text-decoration: none;
    }

    .bg-blur {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: rgba(5, 6, 10, 0.3);
    }

    &__info {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 64px 32px;
        max-width: 800px;
        text-align: start;
        z-index: 2;

        h1 {
            font-weight: 600;
            font-size: 64px;
            line-height: 70px;
            color: #fff;

            span {
                color: #EC1B25;
            }
        }

        p {
            font-weight: 400;
            font-size: 24px;
            line-height: 42px;
            color: #fff;
            margin-top: 32px;
            margin-bottom: 32px;
        }
    }

    @media (max-width: 768px) {
        min-height: 400px;
        
        &__info {
            text-align: center;

            h1 {
                font-weight: 600;
                font-size: 25px;
                line-height: 35px;
                text-align: center;
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;
                text-align: center;
            }

            button {
                margin: 0 auto;
            }
        }
    }
}