.bfs-booking-summary {
    text-align: start;

    & > .head {
        .icon {
            cursor: pointer;
            height: 28px;
            width: 28px;
            border: 1.5px solid #000;
            border-radius: 100%;
        }
    }

    .content {
        font-size: 18px;

        hr {
            height: 1px;
            width: 100%;
            background-color: #F8F7F2;
            opacity: 0.1;
        }

        .value,
        .title {
            text-transform: capitalize;
        }

        .value {
            font-weight: 600;
        }

        .total {
            font-weight: 600;
            font-size: 22px;
            opacity: .8;

            & > div {
                &:last-child {
                    text-align: end;
                }
            }
        }
    }
}