.bfs-extra-service {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;

    & > div.card {
        height: 100px;
        position: relative;
        cursor: pointer;

        svg {
            fill: #EC1B25;
        }

        .overlay {
            outline: none;
            border: none;
            box-shadow: none;
            position: absolute;
            background-color: rgba($color: #dddbd1, $alpha: 0);
            top: 0;
            opacity: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: all .3s linear;
            overflow: hidden;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: stretch;

            & > span {
                display: inline-flex;
                align-items: center;
                font-size: 24px;
                font-weight: bold;
                transition: all .3s linear;

                &:first-child,
                &:last-child {
                    padding: 4px;
                    background-color: #F8F7F2;
                }

                &:first-child:hover {
                    background-color: #EC1B25;
                    color: #fff;
                }

                &:last-child:hover {
                    background-color: #22577A;
                    color: #fff;
                }
            }
        }

        &:hover {
            .overlay {
                opacity: 1;
                background-color: rgba($color: #dddbd1, $alpha: 0.8);
            }
        }
    }

    &.selected {
        & > div.card {
            .overlay {
                opacity: 1;
                background-color: rgba($color: #dddbd1, $alpha: 0.8);
            }
        }
    }
}