.bfs-sidebar {
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 4px;
    height: 100vh;
    flex: 0 0 250px;
    overflow: hidden;
    overflow-y: auto;
    display: block;
    background-color: white;
    border-right: 1px solid #f1eded;
    position: sticky;
    top: 0;
    z-index: 1;

    @media (max-width: 768px) {
        display: none;
        position: relative;
    }

    .logo-container {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color: #e7eef3;

        .avatar {
            object-fit: contain;
            width: 100px;
            height: 100px;
            border-radius: 100px;
        }

        .name {
            font-size: 20px;
            text-align: center;
        }
    }

    .nav-items {
        & > div {
            cursor: pointer;
            gap: 12px;
            color: #a3b9cb;
            transition: all 0.3s linear;

            & > span {
                font-size: 18px;
            }

            &:hover,
            &.active {
                border-left: 2px solid #EC1B25;
                background-color: #e7eef3;
                color: #6d8da7;
                font-weight: 600;
            }
        }

        & > .button {
            background-color: transparent;
            color: #EC1B25;
            border: none;
            opacity: .5;
            transition: all .3s ease;

            &:hover {
                opacity: 1;
                background-color: #e7eef3;
            }
        }
    }
}