.bfs-book-page {
    h1 {
        font-size: 48px;
        margin-top: 32px;
        margin-bottom: 32px;
    }

    & > div {
        background-color: #F8F7F2;
        gap: 24px;

        .left {
            flex: 1;
            min-width: 400px;

            @media (max-width: 768px) {
                min-width: unset;
                width: 100%;
            }
        }

        .right {
            width: 360px;

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}

.bfs-login-page {
    & > div {
        background-color: #F8F7F2;

        .login-form {
            max-width: 400px;

            .password-toggler {
                cursor: pointer;
            }

            .button {
                transition: all 0.3s ease;
            }

            .button:disabled {
                pointer-events: none;
            }
        }
    }
}

.bfs-manage-booking {

    .bookings {
        opacity: 0;
        animation: fadeIn 0.5s 0.5s forwards;
    
        .badge.payment-status {
            font-size: 16px;
        }

        & > .actions {
            gap: 16px;

            .btn {
                background-color: #22577A;
                color: #fff;
            }
        }
    }
}