.cl-about-us {
    padding: 32px;
    height: fit-content;
    min-height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    
    & > div {
        flex: 1;

        img.about {
            object-fit: contain;
            height: 100%;
        }

        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            h4 {
                font-weight: 400;
                font-size: 32px;
                line-height: 38px;
            }

            h1 {
                text-align: start;
                margin-top: 24px;
                font-weight: 600;
                font-size: 64px;
                line-height: 70px;
                color: #000;
            
                span {
                    color: #EC1B25;
                }
            }

            p {
                text-align: start;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: rgba(0, 0, 0, 0.6);
                margin-top: 16px;
            }

            button {
                margin-top: 16px;
            }
        }

        &:last-child {
            margin: auto;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;

        & > div {
            &:first-child {
                h4 {
                    font-size: 12px;
                    line-height: 14px;
                }

                h1 {
                    font-size: 24px;
                    line-height: 33px;
                    text-align: center;
                }

                p {
                    font-size: 24px;
                    line-height: 29px;
                }

                button {
                    margin: 0 auto;
                    margin-top: 16px;
                }
            }

            &:last-child {
                display: none;
            }
        }
    }
}