@keyframes preloader {
    100% {
        transform: scale(2);
    }
}

.bfg-loader {
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 8px;
    align-items: center;
    align-content: center;
    justify-content: center;

    &__element {
        border-radius: 100%;
        border: 2px solid #fff;
        margin: calc(2px * 2);

        &:nth-child(1) {
            animation: preloader .6s ease-in-out alternate infinite;
        }

        &:nth-child(2) {
            animation: preloader .6s ease-in-out alternate .2s infinite;
        }

        &:nth-child(3) {
            animation: preloader .6s ease-in-out alternate .4s infinite;
        }
    }
}