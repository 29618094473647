.bfs-app-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 16.6592px;
    line-height: 24px;
    padding: 12px 32px;
    opacity: 0.8;
    transition: all 0.3s ease;
    cursor: pointer;
    text-decoration: none;
    width: max-content;
    word-break: keep-all;
    outline: none;

    &:hover {
        opacity: 1;
    }

    &:disabled {
        opacity: 0.8;
        pointer-events: none;
    }

    border-radius: 10px;

    &.secondary {
        background-color: #22577A;
        color: #fff;
    }
}