.cl-reviews {
    padding: 32px 0;

    h4 {
        font-weight: 600;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
    }

    .ribbon {
        position: absolute;
        top: 20%;
        left: 0;
        width: 100%;
        height: 119px;
        z-index: -1;
        background-color: #AD393F;
    }

    .reviews {
        & > div {
            gap: 48px;
            position: relative;
            flex-wrap: wrap;                
        }
    }

    .review-card {
        width: 309px;
        background-color: #EC1B25;
        border-radius: 10px;
        color: #fff;
        border: none;

        .reviewer-img {
            height: 120px;
            width: 120px;
            border-radius: 50%;
            object-fit: cover;
            background-color: #ccc;
        }

        .reviewer-name {
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
        }

        .review-text {
            text-align: center;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
        }
    
        .rating {
            color: gold;
        }
    }

    @media (max-width: 768px) {
        padding: 0;

        h4 {
            font-size: 36px;
            line-height: 50px;
            margin: 0 24px;
        }

        .review {
            width: 100%;

            .ribbon {
                border-radius: 10px;
            }
        }

        .review-card {
            margin: 0 auto;
        }
    }
}