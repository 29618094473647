.bfs-book-online-form {
    text-align: start;
    width: 100%;
    overflow: hidden;

    p {
        font-size: 20px;
    }

    hr {
        height: 1px;
        width: 100%;
        background-color: #F8F7F2;
        opacity: 0.1;
        margin: 16px 0;
    }

    .form-label {
        font-weight: 600;
    }

    .card.service_type {
        gap: 16px;
    }

    .time-slots,
    .tips,
    .parking,
    .key_info,
    .frequency-options {
        gap: 16px;
        width: 100%;
    }

    .frequency-options {
        max-width: 500px;
    }

    .button {
        width: 100%;
        outline: none;
        border: none;
        background-color: #EC1B25;
        color: #fff;
    }

    .go-back {
        background-color: #22577A;
        color: #ffffff;
    }
}