.bfs-opt-chip {
    background-color: #F8F7F2;
    padding: 12px;
    color: #000000;
    cursor: pointer;
    border-radius: 4px;
    transition: .3s all ease-in;
    text-align: center;
    width: fit-content;

    &.selected {
        background-color: #22577A;
        color: #fff;
    }
}