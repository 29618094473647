.cl-App {
  text-align: center;
  width: 100%;
  // height: 100vh;
  // position: relative;
  // overflow: hidden;
  // overflow-y: auto;
  // scroll-behavior: smooth;
}

.bfs-app-layout {
  min-height: 100dvh;

  .main-content {
    flex: 1;
    min-width: 0;
  }
}