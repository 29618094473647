@font-face {
  font-family: "PolySans";
  src: local("PolySans"),
    url("./Assets/Fonts/PolySans/PolySans\ Neutral.ttf") format("truetype");
}

$font-family-base: 'PolySans' !default;

// Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: 'PolySans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0 16px;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  opacity: .7;
  transition: all 0.3s ease-in;

  &:hover {
    opacity: 1;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #EC1B25;
  }
}

.input-error {
  color: #EC1B25;
  text-align: start;
  animation: fadeIn 0.3s ease-in;
}

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16.6592px;
  line-height: 24px;
  // letter-spacing: 0.12em;
  padding: 12px 32px;
  opacity: 0.8;
  transition: all 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  width: max-content;
  word-break: keep-all;
  outline: none;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.8;
    pointer-events: none;
  }
}

.button.button-round {
  border-radius: 100px;
}

.button.button-round-rect {
  border-radius: 10px;
}

.button.button-secondary {
  background-color: #22577A;
  color: #fff;
}

.button.call-for-quote,
.button.book-now {
  background: #FFFFFF;
  border: 1px solid #EC1B25;
  color: #EC1B25;

  &:hover {
    background: #EC1B25;
    border: 1px solid #FFFFFF;
    color: #fff;
  }
}

.button.call-for-quote {
  opacity: 1;
}

.button.get-quote {
  background: #EC1B25;
  border: 1px solid #FFFFFF;
  color: #fff;

  &:hover {
    background: #FFFFFF;
    border: 1px solid #EC1B25;
    color: #EC1B25;
  }
}

/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.scrollTop {
  position: fixed;
  top: calc(100vh - 100px);
  right: 20px;
  background-color: #000;
  padding: 10px;
  animation: pulse 2s 0.5s infinite;
  cursor: pointer;
  box-shadow: 0px 10px 15px 19px rgba(0, 0, 0, 0.1), 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 50%;

  &-enter {
    &-active {
      animation: zoomIn 0.5s forwards;
    }
  }

  &-exit {

    &-active {
      animation: zoomOut 0.5s forwards;
    }
  }
}

a {
  text-decoration: none;
  color: #22577A;

  &:hover {
    color: #3c7194;
  }
}

.slide-in-left {
  animation: slideInLeft 0.4s forwards;
}

.slide-out-left {
  animation: slideOutLeft 0.4s forwards;
}