.bfs-input {
    .form-label {
        text-align: start;
        width: 100%;
    }

    .form-control {
        background-color: transparent;
        outline: none;
        border-color: #22577A;
    }

    .input-group-text {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: #22577A;
        color: #fff;
    }
}