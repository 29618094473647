.cl-services {
    padding: 32px;
    width: 100%;
    background-image: url(../../Assets/Images/services-bg.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    h3 {
        font-weight: 600;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        color: #05060A;

        span {
            color: #EC1B25;
        }
    }

    .card {
        margin: 0 auto;
        background: #F4F4F4;
        border: 1px solid rgba(103, 125, 143, 0.33);
        border-radius: 10px;
        max-width: 300px;
        height: 100%;

        &-title {
            font-weight: 600;
            font-size: 30px;
            line-height: 42px;
            text-align: center;
        }

        &-text {
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            color: rgba(0, 0, 0, 0.6);
        }

        &-img {
            background-color: #EC1B25;
            padding: 32px 24px;
            width: fit-content;
            margin: 0 auto;
            border-radius: 12px;
            margin-bottom: 16px;

            img {
                object-fit: contain;
                width: 80%;
            }
        }
    }

    @media (max-width: 768px) {
        h3 {
            font-size: 24px;
            line-height: 33px;
            text-align: center;
        }

        .service {
            margin-bottom: 48px;

            &:nth-child(3) {
                margin-bottom: 0;
            }
        }

        .card {
            // margin-bottom: 16px;

            &-title {
                font-size: 24px;
                line-height: 33px;
            }
        }
    }
}