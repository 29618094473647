.ml-checkbox-round-container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;

  .checkbox-round-wrapper {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: #fff;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }

    .checkbox-round {
      height: 100%;
      width: 100%;
      border-radius: 100%;
      border: 3px solid #22577A;
      background-color: transparent;
      transition: all 0.3s ease-in-out;

      &-label {
        font-size: 14px;
        font-weight: 500;
        color: #272727;
        line-height: 120%;
      }
    }

    & > input:checked ~ .checkbox-round {
      border-width: 8px;
    }
  }
}
