.cl-footer {
    background-color: #EC1B25;
    color: #fff;
    text-align: start;

    .copyright-area {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.6);
        font-size: 24px;
        line-height: 29px;
        gap: 16px;

        .socials {
            gap: 8px;

            a {
                margin: 0;
                padding: 0;
                color: inherit;
            }
        }
    }

    h5 {
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        li {
            a {
                text-decoration: none;
                font-weight: 400;
                font-size: 26px;
                line-height: 31px;
                color: #fff;
            }
        }
    }    

    @media (max-width: 768px) {
        .copyright-area {
            text-align: center;
            flex-direction: column;
        }
    }
}