.bfs-app-header {
    border-bottom: 1px solid #e0dcdc;
    background-color: #fff;
    animation: fadeIn 0.5s forwards;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 4px;
    position: sticky;
    top: 0;
    z-index: 1;

    .hamburger {
        cursor: pointer;
    }

    .logo_container {
        & > h4 {
            color: #22577A;
            font-weight: bold;
        }

        .logo {
            height: 50px;
            object-fit: contain;
            cursor: pointer;
        }
    }
}

.mobile-menu-nav {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    background-color: rgba($color: #0134d4, $alpha: .5);

    &-enter {
        opacity: 0;

        &-active {
            animation: fadeIn 0.5s forwards;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            animation: fadeOut 0.3s forwards;
        }
    }

    &__sidebar {
        height: 100%;
        background-color: #fff;
        overflow: hidden;
        overflow-y: auto;
        width: fit-content;
        width: 250px;
        // left: -250px;
        // animation: slideInLeft 0.5s forwards;
        position: relative;

        & > .bfs-sidebar {
            display: block;
        }
    }
}